/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { defineStore } from 'pinia'
import { useMainStore } from '@/stores/main'
import { useToolsStore } from '@/stores/tools'
import { toSafeUrl, gotKey, isNonEmptyArray } from '@/utils'
import { toString } from '@/components/templates/template-utils'
import { APAM_DEFAULT_SORTING_COLUMN, APAM_DEFAULT_SORTING_ORDER } from '@/config/config-front-end'
import type { ItemInfo } from '@/types/shared.types'

export interface DataState {
    cache: Record<string, any>
    lastUpdated: number
    SEPARATOR: string
}

const idFields = {
    'analytics4-structure': ['accountId', 'propertyId', 'subpropertyId'],
    'tagmanager-structure': ['accountId', 'containerId'],
    'campaignmanager-structure': ['advertiserId', 'subaccountId']
}

export const useMasterDataStore = defineStore('masterData', {
    state: (): DataState => ({
        cache: {},
        lastUpdated: 0,
        SEPARATOR: ';'
    }),

    getters: {
        data(): any {
            const mainStore = useMainStore()
            return mainStore.data
        },

        tools(): any {
            const toolsStore = useToolsStore()
            return toolsStore
        },

        items(): any[] {
            if (this.tools.currentListName in this.data && this.data[this.tools.currentListName]?.items) {
                return this.data[this.tools.currentListName].items
            }
            return this.data.master?.items || []
        },

        matching(): string[] {
            if (this.tools.currentListName in this.data && this.data[this.tools.currentListName]?.matching) {
                return this.data[this.tools.currentListName].matching
            }
            return []
        },

        countries(): string[] {
            return this.data.country;
        },

        divisions(): string[] {
            return this.data.division;
        },

        brands(): string[] {
            return this.data.brand;
        },

        frameworks(): string[] {
            return ["LORA", "MBF V2", "NGLORA", "WSF", "Other"].sort();
        },

        urls(): string[] {
            return this.data.url;
        },
    },

    actions: {
        _toValueObject(rawValue: string) {
            const [actual, expected] = rawValue.split(this.SEPARATOR);
            return {
                actual: (actual !== "") ? actual : null,
                expected: (expected !== "") ? expected : null,
                found: (actual !== null && expected !== null && actual !== "n/a" && expected !== "n/a" && actual !== "")
            };
        },

        getValue(item: any[], prop: string, listName: string = '') {
            if (listName === '') listName = this.tools.currentListName;
            let rawValue = null;

            const list = this.data[listName];

            let matching = [];
            if (listName in this.data && this.data[listName] !== null && "matching" in this.data[listName]) {
                matching = this.data[listName].matching;
            }

            const index = matching.indexOf(prop);
            if (index !== -1) {
                if (prop !== "fetched" && prop in list) { // item[index] is a reference to a value in another array
                    const ref = item[index];
                    rawValue = list[prop][ref];
                } else { // item[index] is an actual value, not a reference
                    rawValue = item[index];
                }
            }

            if (typeof rawValue === "string" && rawValue.includes(this.SEPARATOR)) {
                // rawValue is actually encoded: "<ACTUAL>;<EXPECTED>"
                return this._toValueObject(rawValue);
            } else {
                return rawValue;
            }
        },

        isListEmpty(listName = '') {
            if (listName === '') listName = this.tools.currentListName
            return (!(listName in this.data) || !("items" in this.data[listName]) || this.data[this.tools.currentListName].items.length === 0);
        },

        getMasterListIndex(index: number, item: any[]) {
            return index;
        },

        getBrandInfo(masterListIndex: number, prop: string) {
            const { master } = this.data
            if (!master?.items?.[masterListIndex]) return null

            const item = master.items[masterListIndex]
            const index = master.matching.indexOf(prop)
            return index !== -1 ? this.data[prop][item[index]] : null
        },

        getZoneCountries(zone: string | string[] | null = null): string[] {
            if (zone === null || zone.length == 0) return this.countries;
            const countries: string[] = [], zones = Array.isArray(zone) ? zone : [zone];
            for (let i = 0, len = this.items.length; i < len; i++) {
                const item = this.items[i];
                const masterListIndex = this.getMasterListIndex(i, item);
                const zone = this.getBrandInfo(masterListIndex, 'zone');
                const country = this.getBrandInfo(masterListIndex, 'country');
                if (zones.includes(zone) && !countries.includes(country)) {
                    countries.push(country);
                }
            }
            return countries.sort();
        },

        getDivisionBrands(division: string | string[] | null = null): string[] {
            if (division === null || division.length == 0) return this.brands;
            const brands: string[] = [], divisions = Array.isArray(division) ? division : [division];
            for (let i = 0, len = this.items.length; i < len; i++) {
                const item = this.items[i];
                const masterListIndex = this.getMasterListIndex(i, item);
                const division = this.getBrandInfo(masterListIndex, 'division');
                const brand = this.getBrandInfo(masterListIndex, 'brand');
                if (divisions.includes(division) && !brands.includes(brand)) {
                    brands.push(brand);
                }
            }
            return brands.sort();
        },

        getCountryDivisions(country: string | string[] | null = null): string[] {
            if (country === null || country.length == 0) return this.divisions;
            const divisions: string[] = [], countries = Array.isArray(country) ? country : [country];
            for (let i = 0, len = this.items.length; i < len; i++) {
                const item = this.items[i];
                const masterListIndex = this.getMasterListIndex(i, item);
                const country = this.getBrandInfo(masterListIndex, 'country');
                const division = this.getBrandInfo(masterListIndex, 'division');
                if (countries.includes(country) && !divisions.includes(division)) {
                    divisions.push(division);
                }
            }
            return divisions.sort();
        },

        getCountryBrands(country: string | string[] | null = null): string[] {
            if (country === null || country.length == 0) return this.brands;
            const brands: string[] = [];
            const countries = Array.isArray(country) ? country : [country];
            for (let i = 0, len = this.items.length; i < len; i++) {
                const item = this.items[i];
                const masterListIndex = this.getMasterListIndex(i, item);
                const country = this.getBrandInfo(masterListIndex, 'country');
                const brand = this.getBrandInfo(masterListIndex, 'brand');
                if (countries.includes(country) && !brands.includes(brand)) {
                    brands.push(brand);
                }
            }
            return brands.sort();
        },

        getItemInfo(masterListIndex: number, item: any[], listName = ''): ItemInfo {
            if (listName === '') listName = this.tools.currentListName;
            let matching = this.matching;

            if (listName !== this.tools.currentListName) {
                matching = this.data[listName].matching;
            }

            const itemInfo: ItemInfo = {
                zone: this.getBrandInfo(masterListIndex, 'zone'),
                country: this.getBrandInfo(masterListIndex, 'country'),
                division: this.getBrandInfo(masterListIndex, 'division'),
                brand: this.getBrandInfo(masterListIndex, 'brand'),
                framework: this.getBrandInfo(masterListIndex, 'framework'),
                url: this.getBrandInfo(masterListIndex, 'url'),
                activatedFeatures: this.data.master.activatedFeatures
            }

            for (const prop of matching) {
                if (!(prop in itemInfo)) {
                    itemInfo[prop] = this.getValue(item, prop, listName)
                }
            }

            ['fetched', 'score'].forEach(prop => {
                if (!(prop in itemInfo)) {
                    itemInfo[prop] = null;
                }
            });

            return itemInfo
        },

        getItemInfoByUrl(url: string, listName = ''): ItemInfo {
            if (listName === '') listName = this.tools.currentListName;

            const items = this.data[listName].items;

            if (typeof url === "string" && url.length > 0) {
                let item;
                const urlIndex = this.urls.indexOf(url);
                const urlPropIndex = this.data.master.matching.indexOf('url');
                const masterListIndex = this.data.master.items.findIndex((item: any) => item[urlPropIndex] === urlIndex);
                if (masterListIndex !== -1) {
                    item = items[masterListIndex];
                }
                if (item === undefined) {
                    throw new Error(`Fatal Error: can't find item with url '${url}'`);
                }
                return this.getItemInfo(masterListIndex, item, listName);
            } else {
                throw new Error(`Fatal Error: can't find item with url '${url}'`);
            }
        },

        findSafeUrl(safeUrl: string) {
            const urls = this.urls;
            for (let i = 0, j = urls.length; i < j; i++) {
                if (safeUrl === toSafeUrl(urls[i])) {
                    return urls[i];
                }
            }
            return null;
        },

        transformSortingValue(val: any): number | string {
            if (val === null) return -2
            if (val === 'n/a') return -1
            if (!isNaN(val)) return Number(val)

            if (typeof val === 'string') {
                const parts = val.split(' / ')
                if (parts.length === 2) {
                    return Number(parts[0]) / Number(parts[1])
                }
                return val.toLowerCase()
            }
            return val
        },

        getSortedIndices(column = APAM_DEFAULT_SORTING_COLUMN, order = APAM_DEFAULT_SORTING_ORDER) {
            const sortedIndices = this.items.map((item, i) => {
                const common = ["zone", "country", "division", "brand", "framework", "url"];
                if (common.includes(column)) {
                    const masterListIndex = this.getMasterListIndex(i, item);
                    return [i, this.getBrandInfo(masterListIndex, column)];
                } else {
                    const value = this.getValue(item, column);
                    if (gotKey(value, "actual") && toString(value.actual) !== null) {
                        return [i, toString(value.actual)];
                    } else if (gotKey(value, "expected") && toString(value.expected) !== null) {
                        return [i, toString(value.expected)];
                    } else {
                        return [i, value];
                    }
                }
            });
            const valueIndex = 1;
            const direction = order === "ascending" ? 1 : -1;
            sortedIndices.sort((a, b) => {
                a[valueIndex] = this.transformSortingValue(a[valueIndex]);
                b[valueIndex] = this.transformSortingValue(b[valueIndex]);
                if (a[valueIndex] === -2) {
                    return -direction;
                } else if (b[valueIndex] === -2) {
                    return direction;
                } else if (a[valueIndex] === -1) {
                    return -direction;
                } else if (b[valueIndex] === -1) {
                    return direction;
                } else if (a[valueIndex] < b[valueIndex]) {
                    return -direction;
                } else if (a[valueIndex] > b[valueIndex]) {
                    return direction;
                } else {
                    return 0;
                }
            });
            return sortedIndices.map(indices => indices[0]);
        },

        *filteredItemList(filters: Record<string, any> = {}) {
            const mainStore = useMainStore();
            const toolsStore = useToolsStore();

            const currentItems = toolsStore.currentListName in mainStore.data && mainStore.data[toolsStore.currentListName]?.items
                ? mainStore.data[toolsStore.currentListName].items
                : mainStore.data.master?.items || [];

            if (Object.keys(filters).length === 0) {
                filters = mainStore.filters;
            }

            const props = ['zone', 'country', 'division', 'brand', 'framework'];
            const config = mainStore.sorting;
            const sortedIndices = isNonEmptyArray(config.indices) ? config.indices : this.getSortedIndices();

            if (!sortedIndices) return;

            itemsLoop: for (let i = 0, len = sortedIndices.length; i < len; i++) {
                const itemIndex = sortedIndices[i];
                const item = currentItems[itemIndex];
                const masterListIndex = this.getMasterListIndex(itemIndex, item);
                for (let p = 0; p < props.length; p++) {
                    const brandInfo = this.getBrandInfo(masterListIndex, props[p]);
                    if (filters[props[p]].length > 0 && !filters[props[p]].includes(brandInfo)) {
                        continue itemsLoop;
                    }
                }

                const currentFields = idFields[this.tools.currentListName]
                if (currentFields && filters[currentFields[0]]?.operator) {
                    const fieldValues = currentFields.map(field => this.getValue(item, field))

                    if (filters[currentFields[0]].operator === 'isEmpty') {
                        if (!fieldValues.some(value => value === null || value === '')) {
                            continue itemsLoop
                        }
                    } else if (filters[currentFields[0]].operator === 'isNotEmpty') {
                        if (fieldValues.some(value => value === null || value === '')) {
                            continue itemsLoop
                        }
                    }
                }

                yield this.getItemInfo(masterListIndex, item);
            }
        },

        *cartItemList() {
            const mainStore = useMainStore();
            const cart = mainStore.cart;

            const currentItems = this.tools.currentListName in mainStore.data && mainStore.data[this.tools.currentListName]?.items
                ? mainStore.data[this.tools.currentListName].items
                : mainStore.data.master?.items || [];

            const config = mainStore.sorting;
            const sortedIndices = isNonEmptyArray(config.indices) ? config.indices : this.getSortedIndices();

            if (!sortedIndices) return;

            for (const index of sortedIndices) {
                const item = currentItems[index]
                const masterListIndex = this.getMasterListIndex(index, item)
                const url = this.getBrandInfo(masterListIndex, 'url')

                if (cart.includes(url)) {
                    yield this.getItemInfo(masterListIndex, item)
                }
            }
        },

        *selectionItemList(filters: Record<string, any> = {}) {
            const mainStore = useMainStore();

            const currentItems = this.tools.currentListName in mainStore.data && mainStore.data[this.tools.currentListName]?.items
                ? mainStore.data[this.tools.currentListName].items
                : mainStore.data.master?.items || [];

            if (Object.keys(filters).length === 0) {
                filters = mainStore.filters;
            }

            const props = ['zone', 'country', 'division', 'brand', 'framework'];
            const cart = mainStore.cart;
            const config = mainStore.sorting;
            const sortedIndices = isNonEmptyArray(config.indices) ? config.indices : this.getSortedIndices();
            if (!sortedIndices) return;
            itemsLoop: for (let i = 0, len = sortedIndices.length; i < len; i++) {
                const itemIndex = sortedIndices[i];
                const item = currentItems[itemIndex];
                const masterListIndex = this.getMasterListIndex(itemIndex, item);
                const url = this.getBrandInfo(masterListIndex, 'url');
                if (cart.includes(url)) {
                    for (let p = 0; p < props.length; p++) {
                        const brandInfo = this.getBrandInfo(masterListIndex, props[p]);
                        if (filters[props[p]].length > 0 && !filters[props[p]].includes(brandInfo)) {
                            continue itemsLoop;
                        }
                    }

                    const currentFields = idFields[this.tools.currentListName]
                    if (currentFields && filters[currentFields[0]]?.operator) {
                        const fieldValues = currentFields.map(field => this.getValue(item, field))

                        if (filters[currentFields[0]].operator === 'isEmpty') {
                            if (!fieldValues.some(value => value === null || value === '')) {
                                continue itemsLoop
                            }
                        } else if (filters[currentFields[0]].operator === 'isNotEmpty') {
                            if (fieldValues.some(value => value === null || value === '')) {
                                continue itemsLoop
                            }
                        }
                    }

                    yield this.getItemInfo(masterListIndex, item);
                }
            }
        },
    }
})