import { useMasterDataStore } from '@/stores/master-data'

const instances = new Map<string, ReturnType<typeof useMasterDataStore>>()

export function useDataReader(listName: string = 'master') {
    if (instances.has(listName)) {
        return instances.get(listName)
    }

    const store = useMasterDataStore()
    instances.set(listName, store)

    return store
}