<script setup lang="ts">
import { ref, computed } from 'vue'
import { useMainStore } from '@/stores/main'
import { useToolsStore } from '@/stores/tools'
import { GoogleClient } from '@/google-client/google'
import Button from 'primevue/button'
import { APAM_DISPLAY_AUTHENT_LOGS } from '@/config/environment-front-end'

const mainStore = useMainStore()
const toolsStore = useToolsStore()

const inProgress = ref(false)
const googleClientAuth2Loaded = ref(window.gapi !== undefined)
const googleClient = new GoogleClient()

const actionSignInLabel = 'signin'
const actionLoadOauth2Label = 'use oauth 2.0'

const isAuthValid = computed(() => toolsStore.isAuthenticationTimeValid())
const buttonDisabled = computed(() => mainStore.app.offline || inProgress.value)
const buttonLabel = computed(() => {
  if (isSafari() && !googleClientAuth2Loaded.value) {
    return actionLoadOauth2Label
  }
  return actionSignInLabel
})
const showLoadingDots = computed(() => inProgress.value ? '...' : '')

const isSafari = (): boolean => {
  const userAgent = navigator.userAgent.toLowerCase()
  return userAgent.includes('safari') && !userAgent.includes('chrome')
}

const loadOauth = async (): Promise<void> => {
  mainStore.setLoaderVisible(true)
  inProgress.value = true
  try {
    if (window.google === undefined) {
      await googleClient.loadAndExecuteGSI()
    } else if (window.apam?.gsiClient === undefined) {
      googleClient.gsiInit()
    } else {
      await googleClient.gsiClientRequestToken()
    }
  } catch (e) {
    console.error(e)
    mainStore.showToast('Failed to load OAuth', 'error')
    inProgress.value = false
  }
}

const signIn = async (): Promise<void> => {
  mainStore.setLoaderVisible(true)
  inProgress.value = true

  try {
    if (!toolsStore.isAuthenticationTimeValid()) {
      googleClient.signOut()
    }

    mainStore.showToast('Signing in…', 'info')

    try {
      googleClient.signIn()

      if (APAM_DISPLAY_AUTHENT_LOGS) {
        console.log("[AUTHENT STEPS] Success! User is signed in.")
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      if (e.error === 'popup_blocked_by_browser') {
        mainStore.displayModalError(
          'Error',
          "Your browser is blocking popups, which prevents Google's identification screen from showing.\n\nPlease enable popups and try again."
        )
      } else if (e.error === 'popup_closed_by_user') {
        mainStore.displayModalError(
          'Error',
          "You closed Google's identification screen. Action cannot be completed without user identification."
        )
      } else {
        mainStore.displayModalException(e)
      }

      document.dispatchEvent(new Event('GoogleClientExceptionFired'))
    }
  } catch (e) {
    console.error(e)
    return googleClient.loadAndExecuteGSI()
  } finally {
    inProgress.value = false
  }
}

const handleClick = () => {
  if (isSafari() && !googleClientAuth2Loaded.value) {
    loadOauth()
  } else {
    signIn()
  }
}
</script>

<template>
  <div v-if="!isAuthValid">
    <Button outlined :disabled="buttonDisabled" :loading="inProgress" @click="handleClick">
      {{ buttonLabel }}{{ showLoadingDots }}
    </Button>
  </div>
</template>