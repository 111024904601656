import { createApp } from 'vue'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

import App from '@/App.vue'
import { registerAllTemplates } from '@/components/templates'
import router from '@/router'
import PrimeVue from 'primevue/config'
import Material from '@primeuix/themes/material'
import ToastService from 'primevue/toastservice'

import '@/styles/main.css'

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

registerAllTemplates()
const app = createApp(App)

app.use(pinia)
app.use(router)
app.use(PrimeVue, {
    theme: {
        preset: Material,
        options: {
            darkModeSelector: 'none',
        }
    }
})
app.use(ToastService)

app.mount('#app')