import mitt from 'mitt'

type Events = {
    'FixButtonStopped': void;
    [`${string}_${string}_FixButtonAction`]: void;
    [`${string}_${string}_FixButtonGSIInitialized`]: void;
    'googleGsiInitialized': { lib: string; client: any };
    'google_gsi_connected': { token: string };
    'GoogleClientInitializedFailed': { error?: any; message?: string };
    'GoogleClientAuth2Loaded': void;
    'GoogleClientInitialized': void;
    'GoogleClientExceptionFired': void;
}

export default mitt<Events>()